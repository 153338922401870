import React from "react";
import PropTypes from "prop-types";
import { Field, FormikProvider, useFormik } from "formik";
import Spacer from "../Spacer/Spacer";
import IsrInput from "../IsrInput/IsrInput";
import IsrButton from "../IsrButton/IsrButton";
import "./NewsletterSubscribe.scss";

const NewsletterSubscribe = ({ title, description }) => {
  const newsletterForm = useFormik({
    variables: {
      first_name: "",
      last_name: "",
      title: "",
      affiliation: "",
      email: "",
    },
  });
  return (
    <FormikProvider value={newsletterForm}>
      <form className="newsletter-form" onSubmit={newsletterForm.handleSubmit}>
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
        <Spacer size={50} mobileSize={50} />
        <div className="row">
          <div className="col-md-6">
            <Field
              name="first_name"
              id="first_name"
              component={IsrInput}
              placeholder="First name"
              variant="text"
            />
          </div>
          <div className="col-md-6">
            <Field
              name="last_name"
              id="last_name"
              component={IsrInput}
              placeholder="Last name"
              variant="text"
            />
          </div>
          <div className="col-md-6">
            <Field
              name="title"
              id="title"
              component={IsrInput}
              placeholder="Title"
              variant="text"
            />
          </div>
          <div className="col-md-6">
            <Field
              name="affiliation"
              id="affiliation"
              component={IsrInput}
              placeholder="Affiliation"
              variant="text"
            />
          </div>
          <div className="col-md-6">
            <Field
              name="email"
              id="email"
              component={IsrInput}
              placeholder="Email address"
              variant="email"
            />
          </div>
          <div className="col-md-12 text-center">
            <IsrButton variant="primary" type="submit" size="md">
              Submit
            </IsrButton>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

NewsletterSubscribe.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default NewsletterSubscribe;
