import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { uniqueId } from "lodash";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import IsrButton from "../IsrButton/IsrButton";
import IsrLabel from "../IsrLabel/IsrLabel";
import Spacer from "../Spacer/Spacer";
import NewsletterSubscribe from "../NewsletterSubscribe/NewsletterSubscribe";
import "./Project.scss";

const Project = ({ ProjectData }) => {
  const {
    title,
    projectsCategories: { nodes: categories },
    author,
    date,
    location,
    content,
    featuredImage,
    projectSingle: { country, crisisName, projectStatus },
  } = ProjectData;
  const breakPoints = useBreakpoint();
  return (
    <div className="isr-project">
      <div className="container">
        <div className="project-header">
          {!breakPoints.md && crisisName && (
            <Link to={crisisName.uri}>
              <IsrButton variant="arrow" size="md">
                Back to {crisisName.title}
              </IsrButton>
            </Link>
          )}
          <Link to="/projects" className="second-back-button">
            <IsrButton variant="arrow" size="md">
              Back to Project Directory
            </IsrButton>
          </Link>
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              {title && <h1 className="h3">{title}</h1>}
              <div className="project-details">
                <div>
                  {(author.node.firstName || author.node.lastName) && (
                    <p className="author">{`${author.node.firstName} ${author.node.lastName}`}</p>
                  )}
                  {location && <p className="location">{location}</p>}
                  {date && <p className="date">{date}</p>}
                </div>
                <div className="project-categories">
                  {crisisName && (
                    <Link to={crisisName.uri}>
                      <IsrLabel variant="secondary" size="md" color="grey">
                        {crisisName.title}
                      </IsrLabel>
                    </Link>
                  )}
                  {projectStatus && (
                    <IsrLabel variant="secondary" size="md" color="grey">
                      {projectStatus}
                    </IsrLabel>
                  )}
                  {/* {categories &&
                    categories.map((category) => (
                      <Link
                        to={category.uri}
                        title={category.name}
                        key={uniqueId("bp_cat_")}
                      >
                        <IsrLabel variant="secondary" size="md" color="grey">
                          {category.name}
                        </IsrLabel>
                      </Link>
                    ))} */}
                  {country && (
                    <IsrLabel variant="secondary" size="md" color="grey">
                      {country[1]}
                    </IsrLabel>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <Spacer size={20} mobileSize={20} />
            <hr size={3} className="divider grey" />
            <Spacer size={20} mobileSize={20} />
            <div className="project-content">
              {content && (
                <div // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                  className="project-description"
                />
              )}
              {featuredImage && (
                <>
                  <Spacer size={100} mobileSize={50} />
                  <GatsbyImage
                    className="featured-img"
                    image={
                      featuredImage?.node?.localFile?.childImageSharp
                        ?.gatsbyImageData
                    } // eslint-disable-line react/prop-types
                    alt="featured project"
                  />
                </>
              )}
            </div>
            <Spacer size={70} mobileSize={40} />
            <hr size={3} className="divider grey" />
            <Spacer size={70} mobileSize={40} />
            <NewsletterSubscribe
              title="Want to Know More About the Project?"
              description="Together with our partners, the Center for Humane Technology (CHT) is dedicated to radically reimagining our digital infrastructure. Our mission is to drive a comprehensive shift toward humane technology that supports our well-being, democracy, and shared information environment."
            />
          </div>
          <div className="col-md-2" />
        </div>
        <Spacer size={150} mobileSize={50} />
      </div>
    </div>
  );
};

Project.propTypes = {
  ProjectData: PropTypes.shape({
    projectsCategories: PropTypes.shape({ nodes: PropTypes.array }),
    title: PropTypes.string,
    projectSingle: PropTypes.shape({
      country: PropTypes.array,
      crisisName: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
      projectStatus: PropTypes.string,
    }),
    uri: PropTypes.string,
    author: PropTypes.shape({
      node: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
    location: PropTypes.string,
    date: PropTypes.string,
    content: PropTypes.string,
    featuredImage: PropTypes.object,
  }).isRequired,
};

export default Project;
