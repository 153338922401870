import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import Project from "../components/Project/Project";
import Footer from "../components/Footer/Footer";

const ProjectTemplate = () => {
  return (
    <Layout>
      <Header />
      <Project
        ProjectData={{
          title:
            "Modeling COVID-19 variants of concern emergence and dynamics and impact of public health measures",
          categories: {
            nodes: [
              { uri: "#", name: "Crisis name" },
              { uri: "#", name: "Project status" },
              { uri: "#", name: "Research category" },
              { uri: "#", name: "Country" },
            ],
          },
          projectsCategories: {
            nodes: {
              categories: [
                { uri: "#", name: "Crisis name" },
                { uri: "#", name: "Project status" },
                { uri: "#", name: "Research category" },
                { uri: "#", name: "Country" },
              ],
            },
          },
          projectSingle: {
            country: null,
            crisisName: null,
            projectStatus: null,
          },
          uri: "#",
          author: { node: { name: "PI Yuhua Song" } },
          location: "University of Alabama, Birminghams",
          date: "27 May 2021",
          tag: "Abstract",
          content:
            "COVID-19 is a highly transmissible disease caused by Severe Acute Respiratory Syndrome coronavirus 2 (SARSCoV2). Although vaccine development is critical, it is also a lengthy process. To this end ARIScience has developed a state-of-the art molecular simulation software to identify whether existing FDA-approved drug active compounds may interrupt SARSCoV2 proteins. This quasi quantum simulation software autonomously disassembles SARS-CoV-2 proteins, identifies target areas on the protein, and then identifies drug compounds with highest potential for interruption. If an existing drug compound, or cocktail of compounds, can be discovered to affect the speed, formation, and activity of different parts of multiple viral proteins, a multi-pronged attack strategy to slow down COVID-19 can be developed, which in turn can help save civilian lives in the U.S. ARI can currently simulate 1213 drugs and have already completed simulations against 5 SARS-CoV-2 proteins. Preliminary simulation results are confidentially attached to this request pending subsequent validation steps of our overall research.",
          featuredImage:
            "https://cdn.pixabay.com/photo/2020/03/16/16/29/virus-4937553_1280.jpg",
        }}
      />
      <Footer />
    </Layout>
  );
};

export default ProjectTemplate;
